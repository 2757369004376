import { Button, Text } from '@chakra-ui/react';
import React from 'react';
const MButtonWithText = ({
  href,
  children,
  type = 'normal',
  theme = 'normal',
  ...rest
}) => {
  if (type === 'heading') {
    return (
      <Button
        className="customWordSpacing"
        backgroundColor={theme === 'metabillionaire' ? '#FFFFFF' : 'black'}
        color={theme === 'metabillionaire' ? 'black' : 'white'}
        borderRadius={'50px'}
        overflow={'hidden'}
        whiteSpace={'nowrap'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        paddingX={'5px !important'}
        paddingTop={'23px !important'}
        paddingBottom={'19px !important'}
        marginY={'0px 0px !important'}
        w="100%"
        fontSize={['22px', '20px', '20px']}
        fontFamily={'Hanson'}
        transition={'all .2s ease-in-out'}
        border={theme === 'metabillionaire' ? '2px solid white' : 'none'}
        letterSpacing={'0.8px'}
        textTransform={'uppercase'}
        _hover={
          theme === 'metabillionaire'
            ? {
                backgroundColor: 'black',
                letterSpacing: '.2em',
                color: 'white',
              }
            : {
                letterSpacing: '.2em',
              }
        }
        onClick={() => {
          window.location.href = href ? href : '#';
        }}
        {...rest}
      >
        {children}
      </Button>
    );
  } else {
    return (
      <Button
        backgroundColor={'black'}
        minWidth={'125px'}
        w="-moz-fit-content"
        letterSpacing={'1.5px'}
        _hover={{
          backgroundColor: 'black',
          letterSpacing: '.2em',
        }}
        maxW={'100%'}
        padding={'12px 16px !important'}
        onClick={() => {
          window.location.href = href ? href : '#';
        }}
        {...rest}
      >
        <Text
          textTransform={'uppercase'}
          transition={'letter-spacing .2s ease-in-out'}
          color={'white'}
          fontWeight={'700'}
          fontSize={'20px'}
          wordBreak={'break-all'}
        >
          {children}
        </Text>
      </Button>
    );
  }
};

export default MButtonWithText;
