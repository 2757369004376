import { HStack, Image, VStack, Box } from '@chakra-ui/react';
import React from 'react';

import {
  DiscordIcon,
  InstagramIcon,
  OpenSeaIcon,
  TwitterIcon,
} from '../../assets';
import MButton from '../atoms/MButton';
import MButtonWithText from '../atoms/MButtonWithText';

import { links } from '../../data/links';

const Sidebar = ({ open, onClose }) => {
  return (
    <>
      <Box
        w="100%"
        h="100vh"
        zIndex={60}
        backgroundColor={'rgba(0,0,0,0.55)'}
        position={'fixed'}
        top={0}
        left={0}
        visibility={open ? 'visible' : 'hidden'}
        opacity={open ? 1 : 0}
        onClick={() => {
          onClose();
        }}
      ></Box>
      <VStack
        h="100vh"
        justifyContent={'center'}
        alignItems={'center'}
        paddingX={'8px'}
        position={'fixed'}
        top={'0px'}
        right={'0px'}
        w="100%"
        maxW={['100%', '100%', '400px']}
        backgroundColor={'black'}
        margin={'0px 0px !important'}
        zIndex={60}
        spacing={12}
        transform={open ? 'translateX(0)' : 'translateX(100%)'}
        transition="all 400ms cubic-bezier(0.740, -0.175, 0.000, 1.080)"
        transitionTimingFunction={'cubic-bezier(0.740, -0.175, 0.000, 1.080)'}
      >
        <MButtonWithText
          type="heading"
          onClick={() => {
            window.location.href = '/#about';
            onClose();
          }}
        >
          About
        </MButtonWithText>
        <MButtonWithText
          type="heading"
          onClick={() => {
            window.location.href = '/#roadmap';
            onClose();
          }}
        >
          Roadmap
        </MButtonWithText>
        <MButtonWithText
          type="heading"
          onClick={() => {
            window.location.href = '#faq';
            onClose();
          }}
        >
          {' '}
          Faq
        </MButtonWithText>
        <MButtonWithText
          type="heading"
          onClick={() => {
            window.location.href = '/#team';
            onClose();
          }}
        >
          Team
        </MButtonWithText>
        <MButtonWithText
          type="heading"
          onClick={() => {
            window.location.href = '/mint';
            onClose();
          }}
        >
          Mint
        </MButtonWithText>

        <HStack w="100%" justifyContent={'center'}>
          <MButton>
            <Image
              src={DiscordIcon}
              onClick={() => {
                window.open(links.discord, '_blank');
              }}
              alt="discord logo"
              height={'25px'}
            />
          </MButton>
          <MButton>
            <Image
              src={TwitterIcon}
              onClick={() => {
                window.open(links.twitter, '_blank');
              }}
              alt="twitter logo"
              height={'25px'}
            />
          </MButton>
          <MButton>
            <Image
              src={InstagramIcon}
              onClick={() => {
                window.open(links.instagram, '_blank');
              }}
              alt="instagram logo"
              height={'25px'}
            />
          </MButton>
          {/* <MButton>
            <Image
              src={OpenSeaIcon}
              onClick={() => {
                window.open('', '_blank');
              }}
              alt="opensea logo"
              height={'25px'}
            />
          </MButton> */}
        </HStack>
      </VStack>
    </>
  );
};

export default Sidebar;
