import { Container, VStack, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import MHeading from '../components/atoms/MHeading';
import Loader from '../components/molecules/Loader';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';

import ReactHtmlParser from 'react-html-parser';
import { terms } from '../data/terms';
import MSpacer from '../components/atoms/MSpacer';

const Terms = () => {
  const splashDivRef = useRef();

  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    // Set Margin Top to offset header
    const headerHeight = document.querySelector('#header').offsetHeight;
    setHeaderHeight(headerHeight);
    const marginTop = headerHeight + 'px';
    splashDivRef.current.style.marginTop = marginTop;
  }, []);

  return (
    <>
      <Loader />

      <VStack bgColor={'black'}>
        <Header />
        <VStack
          alignItems={'center'}
          justifyContent={'flex-start'}
          ref={splashDivRef}
          w="100%"
        >
          <Container
            maxW="1200px"
            mx="auto"
            h="100%"
            pt={headerHeight}
            px="50px"
            color={'white'}
            minH={() => {
              const windowHeight = window.innerHeight;

              return windowHeight - headerHeight;
            }}
            d="flex"
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <VStack
              w="100%"
              h="100%"
              justifyContent={'center'}
              alignItems={'center'}
              spacing={10}
            >
              <MHeading type="heading">
                {ReactHtmlParser(terms.terms.title)}
              </MHeading>
              <Text textAlign="start" color={'#878d95'} fontSize={'18px'}>
                {ReactHtmlParser(terms.terms.content)}
              </Text>
            </VStack>

            <MSpacer size="lg" />

            <VStack
              w="100%"
              h="100%"
              justifyContent={'center'}
              alignItems={'center'}
              spacing={10}
            >
              <MHeading type="heading">
                {ReactHtmlParser(terms.ownership.title)}
              </MHeading>
              <Text textAlign="start" color={'#878d95'} fontSize={'18px'}>
                {ReactHtmlParser(terms.ownership.content)}
              </Text>
            </VStack>
          </Container>
        </VStack>
        <Footer />
      </VStack>
    </>
  );
};

export default Terms;
