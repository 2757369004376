import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Box } from '@chakra-ui/react';

const AOSWrap = ({ children, ...rest }) => {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  return (
    <Box
      data-aos={'fade-up'}
      data-aos-duration={'1500'}
      data-aos-delay={'100'}
      padding={'0 0 0 0 !important'}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default AOSWrap;
