module.exports.roadmapData = {
  roadmapItems: [
    {
      icon: 'https://uploads-ssl.webflow.com/61b387f3c93606368f1c0a71/61bd23e74021f70111c97716_on-off-button-p-500.png',
      title: '0% - 18th Jan 2022',
      completed: true,
      description:
        'Planning the 1st Meta Bunnies NFT concept, started design drawing (each of the NFTs were hand drawn individually) .',
    },
    {
      icon: 'https://uploads-ssl.webflow.com/61b387f3c93606368f1c0a71/61bd23e74021f785a0c97710_on-off-button-p-500.png',
      title: '10% - 1st March 2022',
      completed: true,
      description:
        "Marketing started, completed all 7K Meta Bunnies NFTs. As we are expanding, we'll give away 15 Meta Bunnies NFT as bonus once our Meta Bunnies Discord channel reaches 2500 members and instagram account hits 1250 followers.",
    },
    {
      icon: 'https://uploads-ssl.webflow.com/61b387f3c93606368f1c0a71/61bd23e74021f7f4b3c9771f_on-off-button-p-500.png',
      title: '25% - 15th March 2022',
      completed: false,
      description:
        "More giveaways are on the way, thanks to the growth of the campaign's popularity. We'll give away another 20 Meta Bunnies NFT once the discord reach 5000 members and instagram account hits 2500 followers.",
    },
    {
      icon: 'https://uploads-ssl.webflow.com/61b387f3c93606368f1c0a71/61bd23e74021f708d9c97719_on-off-button-p-500.png',
      title: '30% - 1st April 2022',
      completed: false,
      description:
        'Meta Bunnies official launching date, we will have our first ever presale of 1500 Meta Bunnies NFT for 0.06 eth, this is only for whitelisted member. We will have grand prize giveaway of 2 ETH for one lucky winner, once we have sold out all the 1500 Meta Bunnies NFT. ',
    },
    {
      icon: 'https://uploads-ssl.webflow.com/61b387f3c93606368f1c0a71/61bd23e74021f70111c97716_on-off-button-p-500.png',
      title: '50%',
      completed: false,
      description:
        "If you are our earlier Meta Bunnies NFT investor, we will have you covered. We'll take care of the marketing and advertising for you, and we'll spend over 30,000 USD on advertising and marketing to drive our last presale of 1500 Meta Bunnies for 0.07 ETH. We have our dedicated media buyers to help you increase the exposure of your NFT.",
    },
    {
      icon: 'https://uploads-ssl.webflow.com/61b387f3c93606368f1c0a71/61bd23e74021f70111c97716_on-off-button-p-500.png',
      title: '75%',
      completed: false,
      description:
        'We will have the last 4000 Meta Bunnies available to the general public at a price of 0.08 ETH.',
    },
    {
      icon: 'https://uploads-ssl.webflow.com/61b387f3c93606368f1c0a71/61bd23e74021f70111c97716_on-off-button-p-500.png',
      title: '95%',
      completed: false,
      description:
        'Once we have sold all our Meta Bunnies NFT, we will have a lucky draw for all our 7000 proud Meta Bunnies Holder. We will prepare our grand prize of 10ETH for our grand prize winner, and 5 ETH to 3 lucky Meta Bunnies NFT holders.',
    },
    {
      icon: 'https://uploads-ssl.webflow.com/61b387f3c93606368f1c0a71/61bd23e74021f70111c97716_on-off-button-p-500.png',
      title: '100%',
      completed: false,
      description:
        'We will spend 30% of our royalty earnings to set a floor price below our official mint price of 0.08 ETH. Besides, 20% of our Royalty will be used as giveaway for our loyal NFT holders. We will have 3 iPad, 3 Apple Watches and 3 Playstation 5 up for grab. ',
    },
  ],
  description:
    'Welcome to Meta Bunnies Club, a group of 7,000 cosmic bunnies that takes you to the hype party! <br></br> Bunnies are adorable and cuddly, but meta bunnies are a very different species. A single psychopath scientist mutated 7,000 rabbits into an assortment of hip and stylish creatures. Everyone has learned to communicate, dress up, and have a good time! After all, they had all evacuated the laboratory because of an issue there.',
};
