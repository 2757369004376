module.exports.about = {
  title: 'ABOUT META BUNNIES CLUB',
  firstPara:
    '"Meta Bunnies Club" is the first NFT collection fully crafted by hand drawing on the Ethereum blockchain. No two of them are identical. <br></br> Our Designer Team crafted 7,000 randomly generated Bunny characters with 12 unique attributes for the collection, each one different from the others. <br></br> Meta Bunnies Club are separated into 3 different generations',
  saleOptions: {
    firstPresale: {
      title: 'Presale 1 (Opening 1st April 2022):',
      content: '2000 on sale',
    },
    secondPresale: {
      title: 'Presale 2 (TBA):',
      content: '1500 on sale',
    },
    publicSale: {
      title: 'Public Sale (TBA):',
      content: '4000 on sale',
    },
  },
  secondPara:
    'For those who own a Meta Bunnies, they"ll be able to join a private chat room only for Meta Bunnies NFT holder. Throughout this group, we will discuss on the future roadmap for meta bunnies club. You will also stand a chance to win the grand prize of 20 ETH, 3 x 10ETH, or 25 of our most exclusive (rare) Meta Bunnies NFTs .',
  discord: {
    title:
      'Please check out our roadmap and join our Discord channel for further details.',
    link: 'https://discord.gg/94QjDuQxeG',
  },
  thirdPara:
    'There are 7,000 fascinating Meta Bunnies in the Meta Bunnies Club, and you can proudly showcase it to the public once you have minted it. We will hvae our first presale on 1st April 2022. Become a Meta Bunny NFT investor and with us and be one of the most talked-about characters in the Metaverse. <br></br> In the Metaverse, the Meta Bunnies live in peace on a Meta Bunnies Land. They were living a calm existence there, where they might occasionally take part in some partying event, and it was truly a paradise. A scarcity of food and resources in Meta Bunny Land forced the king to bring an army (the rare collection) into the Metaverse to gather the greatest resources and bring them back to Meta Bunnies Land.<br></br> One day, the Meta Bunny Land was attacked by the wicked Aliens after they discovered that Meta Bunny Land"s king had gone outside to the Metaverse. The queen of Meta Bunny Land then called on all other Meta Bunnies to join them in defending their beloved home! While all of this is happening, Meta Bunny Land"s king is making his way home. But before that, the Meta Bunny Land"s destiny remains unclear…',
  launch_date: 'Meta Bunnies will have their first presale on 1st April 2022.',
};
