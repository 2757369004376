import { Img, VStack, Flex, Box } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import MButtonWithText from '../atoms/MButtonWithText';

import {
  Backdrop,
  Girl1,
  Girl2,
  Girl3,
  Girl4,
  Girl5,
  Girl6,
  Girl7,
  Girl8,
  MobileBackdrop,
  MobileGirl1,
  MobileGirl2,
  MobileGirl3,
  MobileGirl4,
  MobileGirl5,
  MobileGirl6,
} from '../../assets';

const Splash = () => {
  const [headerHeight, setHeaderHeight] = React.useState(0);

  const imgBoxRef = useRef();
  const Sample1Ref = useRef();
  const Sample2Ref = useRef();
  const Sample3Ref = useRef();
  const Sample4Ref = useRef();
  const Sample5Ref = useRef();
  const Sample6Ref = useRef();
  const Sample7Ref = useRef();
  const Sample8Ref = useRef();
  const bottomIdentifierRef = useRef();
  const splashDivRef = useRef();

  let girlGirlImageArr = [];
  let girlGirlOrigins = [];
  let girlGirlRanges = [];
  let girlGirlRefArr = [];

  if (window.screen.width <= 480) {
    girlGirlImageArr = [
      MobileGirl1,
      MobileGirl4,
      MobileGirl2,
      MobileGirl3,
      MobileGirl5,
      MobileGirl6,
    ];
  } else {
    girlGirlImageArr = [Girl1, Girl5, Girl2, Girl6, Girl3, Girl7, Girl4, Girl8];
  }

  if (window.screen.width <= 480) {
    girlGirlRefArr = [
      Sample1Ref,
      Sample4Ref,
      Sample2Ref,
      Sample3Ref,
      Sample5Ref,
      Sample6Ref,
    ];
  } else {
    girlGirlRefArr = [
      Sample1Ref,
      Sample5Ref,
      Sample2Ref,
      Sample6Ref,
      Sample3Ref,
      Sample7Ref,
      Sample4Ref,
      Sample8Ref,
    ];
  }

  if (window.screen.width <= 480) {
    girlGirlOrigins = [
      { x: -350, y: -150, direction: 'right' },
      { x: -100, y: -225, direction: 'right' },
      { x: -200, y: -250, direction: 'right' },
      { x: 100, y: -225, direction: 'left' },
      { x: 200, y: -250, direction: 'left' },
      { x: 350, y: -200, direction: 'left' },
    ];
  } else {
    girlGirlOrigins = [
      { x: -350, y: -150, direction: 'right' },
      { x: -350, y: -200, direction: 'right' },
      { x: -100, y: -225, direction: 'right' },
      { x: -200, y: -250, direction: 'right' },
      { x: 100, y: -225, direction: 'left' },
      { x: 200, y: -250, direction: 'left' },
      { x: 350, y: -200, direction: 'left' },
      { x: 350, y: -200, direction: 'left' },
    ];
  }

  if (window.screen.width <= 480) {
    girlGirlRanges = [
      { x: 350, y: 150 },
      { x: 100, y: 225 },
      { x: 200, y: 250 },
      { x: 100, y: 225 },
      { x: 200, y: 250 },
      { x: 350, y: 200 },
    ];
  } else {
    girlGirlRanges = [
      { x: 350, y: 150 },
      { x: 350, y: 200 },
      { x: 100, y: 225 },
      { x: 200, y: 250 },
      { x: 100, y: 225 },
      { x: 200, y: 250 },
      { x: 350, y: 200 },
      { x: 350, y: 200 },
    ];
  }

  function isElementVisible(el) {
    var rect = el.getBoundingClientRect(),
      vWidth = window.innerWidth || document.documentElement.clientWidth,
      vHeight = window.innerHeight || document.documentElement.clientHeight,
      efp = function (x, y) {
        return document.elementFromPoint(x, y);
      };

    // Return false if it's not in the viewport
    if (
      rect.right < 0 ||
      rect.bottom < 0 ||
      rect.left > vWidth ||
      rect.top > vHeight
    )
      return false;

    // Return true if any of its four corners are visible
    return (
      el.contains(efp(rect.left, rect.top)) ||
      el.contains(efp(rect.right, rect.top)) ||
      el.contains(efp(rect.right, rect.bottom)) ||
      el.contains(efp(rect.left, rect.bottom))
    );
  }

  const handleScroll = (e) => {
    const bottomIdentifier = bottomIdentifierRef.current;

    const currentScrollingProgressInPercentage =
      (window.scrollY / (splashDivRef.current.offsetHeight / 2)) * 100;

    girlGirlRefArr.map((ref, index) => {
      const origin = girlGirlOrigins[index];
      const range = girlGirlRanges[index];

      let x, y;

      if (origin.direction === 'right') {
        x = origin.x + range.x * (currentScrollingProgressInPercentage / 100);
        y = origin.y + range.y * (currentScrollingProgressInPercentage / 100);

        if (x > 0) {
          x = 0;
        }
      } else {
        x = origin.x - range.x * (currentScrollingProgressInPercentage / 100);
        y = origin.y + range.y * (currentScrollingProgressInPercentage / 100);

        if (x < 0) {
          x = 0;
        }
      }

      if (y > 0) {
        y = 0;
      }

      if (isElementVisible(bottomIdentifier)) {
        if (
          window.scrollY + window.innerHeight >
          splashDivRef.current.offsetHeight + headerHeight
        ) {
          ref.current.style = `position: absolute; bottom: 0; transform: translate3d(${x}px, ${y}px, 0px);`;
          imgBoxRef.current.style = `position: absolute; bottom: -0px;`;
          if (window.screen.width <= 480) {
            imgBoxRef.current.style = `position: absolute; top: 30%;`;
          }
        } else {
          ref.current.style = `position: fixed; bottom: 0; transform: translate3d(${x}px, ${y}px, 0px);`;
          imgBoxRef.current.style = `position: fixed; bottom: -0px;`;
          if (window.screen.width <= 480) {
            imgBoxRef.current.style = `position: fixed; top: 30%;`;
          }
        }
      } else {
        if (window.scrollY === 0) {
          imgBoxRef.current.style = `position: fixed; bottom: -0px;`;
          if (window.screen.width <= 480) {
            imgBoxRef.current.style = `position: fixed; top: 30%;`;
          }
        }
        if (
          window.scrollY + window.innerHeight >
          splashDivRef.current.offsetHeight + headerHeight
        ) {
          ref.current.style = `position: absolute; bottom: 0; transform: translate3d(${x}px,${y}px, 0px);`;
          imgBoxRef.current.style = `position: absolute; bottom: -0px;`;
          if (window.screen.width <= 480) {
            imgBoxRef.current.style = `position: absolute; bottom: 30%; top: auto;`;
          }
        } else {
          ref.current.style = `position: fixed; bottom: 0; transform: translate3d(${x}px,${y}px, 0px);`;
          imgBoxRef.current.style = `position: fixed; bottom: -0px;`;
          if (window.screen.width <= 480) {
            imgBoxRef.current.style = `position: fixed; top: 30%;`;
          }
        }
      }

      return null;
    });
  };

  useEffect(() => {
    // Get window height
    const boxHeight = imgBoxRef.current.offsetHeight;
    const height = window.innerHeight - boxHeight - 8;
    if (window.screen.width <= 480) {
      imgBoxRef.current.style.top = `30%`;
    } else {
      imgBoxRef.current.style.top = `${height}px`;
    }

    // Set Margin Top to offset header
    const headerHeight = document.querySelector('#header').offsetHeight;
    setHeaderHeight(headerHeight);
    const marginTop = headerHeight + 'px';
    splashDivRef.current.style.marginTop = marginTop;
  }, [setHeaderHeight]);

  useEffect(() => {
    window.addEventListener('scroll', (e) => handleScroll(e));

    return () => {
      window.removeEventListener('scroll', (e) => handleScroll(e));
    };
  });

  return (
    <>
      <VStack
        w="100%"
        h="200vh !important"
        backgroundImage={[MobileBackdrop, Backdrop, Backdrop]}
        backgroundSize="contain"
        position={'relative'}
        overflowY={'none !important'}
        ref={splashDivRef}
        id="stage"
      >
        <Box
          id="bottomIdentifier"
          w=".1px"
          h=".1px"
          position={'absolute'}
          bottom={'0px'}
          left={'0px'}
          ref={bottomIdentifierRef}
          content={' '}
        />

        <Box
          id="overlay"
          w="100%"
          h="100%"
          position={'absolute'}
          top="0px"
          left={'0px'}
          margin={'0 0 0 0 !important'}
          backgroundColor={'rgba(0,0,0,0.75)'}
        />

        {girlGirlOrigins.map((origin, index) => {
          return (
            <Img
              src={girlGirlImageArr[index]}
              alt="ApeDefault"
              position={'fixed'}
              bottom={'0px'}
              transform={`translate3d(${girlGirlOrigins[index].x}px, ${girlGirlOrigins[index].y}px, 0px)`}
              height={['auto', '400px', '600px']}
              width={['120%', 'auto', 'auto']}
              ref={girlGirlRefArr[index]}
              zIndex={50}
            />
          );
        })}

        <Flex
          flexDir={'column'}
          position={'fixed'}
          alignItems={'center'}
          ref={imgBoxRef}
          w="100%"
          zIndex={51}
        >
          <MButtonWithText
            w="fit-content"
            type="heading"
            theme="metabillionaire"
            paddingY="35px !important"
            paddingX="35px !important"
            border={'2px solid white'}
            backgroundColor={'black'}
            color={'white'}
            onClick={() => {
              window.location.href = '/mint';
            }}
          >
            Mint MTBC Now
          </MButtonWithText>
        </Flex>
      </VStack>
    </>
  );
};

export default Splash;
