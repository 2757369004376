import { HStack, Link, Image, SimpleGrid } from '@chakra-ui/react';
import React from 'react';

import Sidebar from './Sidebar';

import MButtonWithText from '../atoms/MButtonWithText';
import { UserWhiteIcon, MenuWhiteIcon, Logo } from '../../assets';

import { links } from '../../data/links';

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <>
      <SimpleGrid
        w="100%"
        paddingY={['10px', '10px', '20px']}
        paddingX={['10px', '10px', '20px']}
        boxShadow={
          'rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px'
        }
        position={'fixed'}
        zIndex={61}
        top={0}
        left={0}
        width={'100vw'}
        backgroundColor={'rgba(0,0,0,1)'}
        justifyContent={'space-between'}
        columns={2}
        id="header"
      >
        <HStack
          w="100%"
          maxW={'container.xl'}
          justifyContent={'flex-start'}
          background={'transparent'}
        >
          <Image
            src={Logo}
            alt={'METABunniesLogo'}
            h={['auto', 'auto', '65px']}
            w="auto"
            onClick={() => {
              window.location.href = '/';
            }}
            cursor="pointer"
          />
        </HStack>

        <HStack
          w={['100%', '100%', 'auto']}
          justifySelf={['center', 'center', 'flex-end']}
          justifyContent={'flex-end'}
          alignItems={'center'}
          mt={'0px'}
          overflow={'hidden'}
        >
          <MButtonWithText
            type="heading"
            theme="metabillionaire"
            marginRight={'25px !important'}
            transformOrigin={'right'}
            display={['none', 'none', 'flex']}
            onClick={() => {
              window.open(links.discord, '_blank');
            }}
          >
            JOIN DISCORD
          </MButtonWithText>
          {/* <Link
            marginRight={'25px !important'}
            w="40px !important"
            display={['none', 'none', 'block']}
          >
            <Image src={UserWhiteIcon} alt={'Profile'} />
          </Link> */}
          <Link
            marginRight={[
              '25px !important',
              '10px !important',
              '25px !important',
            ]}
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
            }}
            w={['25px', '25px', '40px']}
          >
            <Image src={MenuWhiteIcon} alt={'Menu'} />
          </Link>
        </HStack>
      </SimpleGrid>
      <Sidebar
        open={sidebarOpen}
        onClose={() => {
          setSidebarOpen(false);
        }}
      />
    </>
  );
};

export default Header;
