import { Center, Container, VStack, HStack, Image } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import MHeading from '../atoms/MHeading';

import MButton from '../atoms/MButton';
import MButtonWithText from '../atoms/MButtonWithText';

import {
  DiscordIcon,
  InstagramIcon,
  OpenSeaIcon,
  TwitterIcon,
} from '../../assets';

import Fade from 'react-reveal/Fade';
import { links } from '../../data/links';

const Footer = () => {
  useEffect(() => {
    // AOS.init({
    //   once: true,
    // });
  }, []);

  return (
    <Fade bottom>
      <Center h="100vh" w="100%">
        <Container maxW="1200px" mx="auto" px="20px">
          <VStack w="100%" alignItems={'center'} spacing={12}>
            <MHeading textAlign="center" type="heading">
              META BUNNIES CLUB
            </MHeading>
            <MHeading type="normal">All Rights Reserved 2022</MHeading>
            <MButtonWithText
              onClick={() => {
                window.location.href = '/terms';
              }}
            >
              Terms and conditions
            </MButtonWithText>
            {/* <MButtonWithText>
            0xF3114DD5c5b50a573E66596563D15A630ED359b4
          </MButtonWithText> */}

            <HStack w="100%" justifyContent={'center'}>
              <MButton py="10px">
                <Image
                  src={DiscordIcon}
                  onClick={() => {
                    window.open(links.discord, '_blank');
                  }}
                  alt="discord logo"
                  height={'38px'}
                />
              </MButton>
              <MButton>
                <Image
                  src={TwitterIcon}
                  onClick={() => {
                    window.open(links.twitter, '_blank');
                  }}
                  alt="twitter logo"
                  height={'38px'}
                />
              </MButton>
              <MButton>
                <Image
                  src={InstagramIcon}
                  onClick={() => {
                    window.open(links.instagram, '_blank');
                  }}
                  alt="instagram logo"
                  height={'38px'}
                />
              </MButton>{' '}
              {/* <MButton onClick={window.open('#', '_blank')}>
                <Image src={OpenSeaIcon} alt="opensea logo" height={'38px'} />
              </MButton> */}
            </HStack>
          </VStack>
        </Container>
      </Center>
    </Fade>
  );
};

export default Footer;
