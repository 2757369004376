import { Center, Container, VStack, HStack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { BsChevronCompactDown as DownArrow } from 'react-icons/bs';
import ReactHtmlParser from 'react-html-parser';

import MHeading from '../atoms/MHeading';
import MSpacer from '../atoms/MSpacer';

import Fade from 'react-reveal/Fade';

import { faqItems } from '../../data/faqs';

const Faq = () => {
  const [selectedFaq, setSelectedFaq] = React.useState();

  useEffect(() => {
    // AOS.init({
    //
    //   once: true,
    // });
  }, []);

  return (
    <Fade bottom>
      <Center w="100%">
        <Container maxW="1100px" mx="auto" px="20px">
          <VStack w="100%" alignItems={'center'} spacing={8}>
            <MHeading type="heading">FAQs</MHeading>
            <MSpacer size="2xs" />

            {faqItems.map((item) => (
              <VStack
                w="100%"
                bgColor={'#2C2F2D !important'}
                borderRadius={'8px'}
                py="5"
                px="5"
                alignItems={'flex-start'}
                idx={item.id}
                id={item.id}
                onClick={() => {
                  if (item.id === selectedFaq) {
                    setSelectedFaq(null);
                  } else {
                    setSelectedFaq(item.id);
                  }
                }}
                height="auto"
              >
                <HStack
                  w="100%"
                  justifyContent={'space-between'}
                  cursor={'pointer'}
                >
                  <MHeading
                    type="normal"
                    textAlign="start"
                    color="#78867F"
                    fontWeight="700"
                    letterSpacing="normal"
                    w="80%"
                  >
                    {item.question}
                  </MHeading>
                  <DownArrow
                    style={{
                      width: '25px !important',
                      height: '25px !important',
                      transform:
                        selectedFaq === item.id ? 'rotate(180deg)' : '',
                      transition: 'transform 0.2s ease-in-out',
                      color: 'white',
                      fill: 'white',
                    }}
                  />
                </HStack>
                <Text
                  marginBottom={'0px !important'}
                  marginTop={
                    item.id === selectedFaq ? '20px' : '0px !important'
                  }
                  transform={
                    item.id === selectedFaq ? 'scaleY(1)' : 'scaleY(0)'
                  }
                  maxHeight={item.id === selectedFaq ? '500px' : '0px'}
                  opacity={item.id === selectedFaq ? '1' : '0'}
                  textAlign={'start'}
                  transition="all 400ms cubic-bezier(0.740, -0.175, 0.000, 1.080)"
                  transitionTimingFunction={
                    'cubic-bezier(0.740, -0.175, 0.000, 1.080)'
                  }
                  transformOrigin={'top'}
                  color={'#878d95'}
                  fontSize={'18px'}
                >
                  {ReactHtmlParser(item.answer)}
                </Text>
              </VStack>
            ))}
          </VStack>
        </Container>
      </Center>
    </Fade>
  );
};

export default Faq;
