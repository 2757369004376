import { Heading } from '@chakra-ui/react';
import React from 'react';

const MHeading = ({ type = 'normal', children, ...rest }) => {
  if (type === 'normal') {
    return (
      <Heading
        color={'white'}
        letterSpacing={'normal'}
        fontWeight={200}
        fontSize={'22px'}
        textTransform={'uppercase'}
        fontFamily={'Montserrat'}
        {...rest}
      >
        {children}
      </Heading>
    );
  } else {
    return (
      <Heading
        color={'white'}
        letterSpacing={'normal'}
        fontWeight={'bold'}
        fontSize={'40px'}
        textTransform={'uppercase'}
        {...rest}
      >
        {children}
      </Heading>
    );
  }
};

export default MHeading;
