import {
  Container,
  Center,
  VStack,
  Image,
  SimpleGrid,
  Text,
  Box,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import {
  TeamMember1,
  TeamMember2,
  TeamMember3,
  TeamMember4,
} from '../../assets';

import MHeading from '../atoms/MHeading';
import MSpacer from '../atoms/MSpacer';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Fade from 'react-reveal/Fade';

const teamMembers = [
  {
    id: 1,
    name: 'Mr.Queen',
    position: 'CEO (Founder)',
    image: TeamMember1,
  },
  {
    id: 2,
    name: 'Mr.Joker',
    position: 'COO (Artist) Artwork control',
    image: TeamMember4,
  },
  {
    id: 3,
    name: 'Mr.Clover',
    position: 'Blockchain expert',
    image: TeamMember2,
  },

  {
    id: 4,
    name: 'Mr.Spade',
    position: 'Community leader',
    image: TeamMember3,
  },
];

const Team = () => {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  return (
    <Fade bottom>
      <Center
        w="100%"
        // data-aos={'fade-up'}
        // data-aos-duration={'1500'}
        // data-aos-delay={'100'}
      >
        <Container maxW="1200px" mx="auto" px="20px">
          <VStack w="100%" alignItems={'center'}>
            <MHeading type="heading">Team</MHeading>
            <MSpacer size="md" />
            <SimpleGrid columns={[2, 2, 4]} spacing={[3, 6, 12]}>
              {teamMembers.map((member) => (
                <VStack
                  position={'relative'}
                  d="flex"
                  alignItems="center"
                  justifyContent={'flex-start'}
                  h="100%"
                >
                  <Box
                    id="imagebox"
                    position={'relative'}
                    w="100%"
                    h="fit-content"
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                  >
                    <Image
                      src={member.image}
                      alt={member.name}
                      borderRadius="50%"
                      mb="22px"
                      w={['70%', '80%', '100%', '100%']}
                      h="auto"
                    />
                  </Box>

                  <MHeading
                    type="qwe"
                    fontSize={[
                      '18px !important',
                      '22px !important',
                      '22px !important',
                    ]}
                  >
                    {member.name}
                  </MHeading>
                  <Text color={'#878d95'} fontSize={['15px', '18px', '18px']}>
                    {member.position}
                  </Text>
                </VStack>
              ))}
            </SimpleGrid>
          </VStack>
        </Container>
      </Center>
    </Fade>
  );
};

export default Team;
