import { Button } from '@chakra-ui/react';
import React from 'react';

const MButton = ({
  href,
  children,
  type = 'normal',
  theme = 'normal',
  ...rest
}) => {
  if (type === 'normal') {
    return (
      <Button
        backgroundColor={theme === 'metabillionaire' ? '#FFFFFF' : 'black'}
        color={theme === 'metabillionaire' ? 'black' : 'white'}
        borderRadius={'50px'}
        paddingX={'20px !important'}
        onClick={() => {
          window.location.href = href ? href : '#';
        }}
        {...rest}
      >
        {children}
      </Button>
    );
  } else {
    return (
      <Button
        backgroundColor={theme === 'metabillionaire' ? '#FFFFFF' : 'black'}
        color={theme === 'metabillionaire' ? 'black' : 'white'}
        borderRadius={'50px'}
        overflow={'hidden'}
        whiteSpace={'nowrap'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        paddingX={'5px !important'}
        w="100%"
        _hover={{
          backgroundColor: 'black',
          letterSpacing: '.2em',
        }}
        onClick={() => {
          window.location.href = href ? href : '#';
        }}
        {...rest}
      >
        {children}
      </Button>
    );
  }
};

export default MButton;
