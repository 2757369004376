import React, { useState } from 'react';
import './App.css';
import Home from './screens/Home';
import Minting from './screens/Minting';
import Terms from './screens/Terms';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  const [web3props, setWeb3Props] = useState({
    web3: null,
    accounts: null,
    contract: null,
  });

  // Callback function for the Login component to give us access to the web3 instance and contract functions
  const OnLogin = function (param) {
    let { web3, accounts, contract } = param;
    if (web3 && accounts && accounts.length && contract) {
      setWeb3Props({ web3, accounts, contract });
    }
  };

  // If the wallet is connected, all three values will be set. Use to display the main nav below.
  const contractAvailable = !(
    !web3props.web3 &&
    !web3props.accounts &&
    !web3props.contract
  );
  // Grab the connected wallet address, if available, to pass into the Login component
  const walletAddress = web3props.accounts ? web3props.accounts[0] : '';

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home onWeb3Login={OnLogin} />} />
        <Route path="/mint" element={<Minting />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </Router>
  );
}

export default App;
