import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import useWindowResize from '../../hooks/useWindowResize';

const Modal = ({
  modalIsOpen,
  onClose,
  closeBtnPos = 'right',
  children,
  customWidth,
  ...props
}) => {
  const modalAnimation = {
    hidden: {
      x: '-50%',
      y: '-50%',
      scale: 0.8,
      opacity: 0,
      transition: {
        delay: 0.2,
      },
    },

    visible: {
      x: '-50%',
      y: '-50%',
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },

    exit: {
      x: '-50%',
      y: '-50%',
      scale: 0.8,
      opacity: 0,
      transition: {
        delay: 0.15,
        duration: 0.15,
      },
    },
  };
  // eslint-disable-next-line no-unused-vars
  const [windowWidth, windowHeight] = useWindowResize();
  return (
    <AnimatePresence exitBeforeEnter={true}>
      {modalIsOpen && (
        <>
          <motion.div
            className="modal-overlay"
            onClick={onClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          ></motion.div>
          <motion.div
            variants={modalAnimation}
            className="METAModal"
            initial="hidden"
            animate="visible"
            exit="exit"
            style={{
              backgroundColor: '#ffffff',
              transition: 'height 0.3s ease-in-out',
              maxHeight: windowWidth > 650 ? '90%' : '85%',
              width:
                windowWidth > 650
                  ? customWidth
                    ? customWidth
                    : '700px'
                  : '90%',
              padding: windowWidth > 650 ? '20px' : '25px',
            }}
            {...props}
          >
            <Box
              w="100%"
              h="100%"
              padding={['0px', '0px', '1.5rem']}
              position="relative"
            >
              <Box
                d={closeBtnPos === 'right' ? 'flex' : 'none'}
                position="absolute"
                top="0"
                right={'0'}
                left={'0'}
                justifyContent={
                  closeBtnPos === 'right' ? 'flex-end' : 'flex-start'
                }
                onClick={onClose}
                zIndex={100}
              >
                <IconButton
                  variant="ghost"
                  aria-label="Close modal"
                  _hover={{
                    bg: '#F5F5F5',
                  }}
                  icon={<CloseIcon w="15px" h="15px" />}
                  zIndex={100}
                />
              </Box>
              {children}
            </Box>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

Modal.propTypes = {
  modalIsOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  closeBtnPos: PropTypes.string,
  customWidth: PropTypes.string,
};

export default Modal;
