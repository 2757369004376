import {
  Text,
  Container,
  Center,
  Image,
  VStack,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react';
import React from 'react';

import MSpacer from '../atoms/MSpacer';
import LazyLoad from 'react-lazyload';
import ReactHtmlParser from 'react-html-parser';

import { GIF1, GIF2 } from '../../assets';
import MHeading from '../atoms/MHeading';

import Fade from 'react-reveal/Fade';
import { why_mtbc } from '../../data/why_mtbc';
import { about } from '../../data/about';
import { links } from '../../data/links';

const Welcome = () => {
  return (
    <Center w="100%">
      <Container maxW={'1200px'} mx="auto" px="50px">
        <VStack w="100%" alignItems={'center'}>
          <Fade bottom>
            <MHeading
              type="heading"
              // data-aos={'fade-up'}
              // data-aos-duration={'1500'}
              // data-aos-delay={'100'}
            >
              About Meta Bunnies Club
            </MHeading>
          </Fade>
          <MSpacer size="sm" />
          <Fade bottom>
            <Text
              textAlign="center"
              // data-aos={'fade-up'}
              // data-aos-duration={'1500'}
              // data-aos-delay={'100'}
              color={'#878d95'}
              fontSize={'18px'}
            >
              {ReactHtmlParser(about.firstPara)}
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <strong style={{ color: '#C7C7C7', fontSize: '22px' }}>
                {ReactHtmlParser(
                  `${about.saleOptions.firstPresale.title} ${about.saleOptions.firstPresale.content}`,
                )}
              </strong>
              <br></br>
              <br></br>
              <strong style={{ color: '#C7C7C7', fontSize: '22px' }}>
                {ReactHtmlParser(
                  `${about.saleOptions.secondPresale.title} ${about.saleOptions.secondPresale.content}`,
                )}
              </strong>
              <br></br>
              <br></br>
              <strong style={{ color: '#C7C7C7', fontSize: '22px' }}>
                {ReactHtmlParser(
                  `${about.saleOptions.publicSale.title} ${about.saleOptions.publicSale.content}`,
                )}
              </strong>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              {ReactHtmlParser(about.secondPara)}
              <br></br>
              <br></br>
              <strong style={{ color: '#C7C7C7' }}>
                {ReactHtmlParser(about.discord.title)}
              </strong>
              <br></br>
              <br></br>
              <div id="discord-button">
                <a href={links.discord} target="_blank" rel="noreferrer">
                  <div class="icon">
                    <svg
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 245 240"
                    >
                      <path
                        class="st0"
                        d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z"
                      />
                      <path
                        class="st0"
                        d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z"
                      />
                    </svg>
                  </div>
                  <span>Join the Discord</span>
                </a>
              </div>
              <br></br>
              <br></br>
              <Divider my="20px" />
              <br></br>
              <br></br>
              {ReactHtmlParser(about.thirdPara)}
              <br></br>
              <br></br>
              <Divider my="20px" />
              <br></br>
              <br></br>
              <strong className="strong" style={{ color: '#C7C7C7' }}>
                {ReactHtmlParser(about.launch_date)}
              </strong>
            </Text>
          </Fade>

          <MSpacer size="sm" />

          <Fade bottom>
            <SimpleGrid
              columns={[1, 1, 2]}
              spacing={'40px'}
              alignItems="center"
              // data-aos={'fade-up'}
              // data-aos-duration={'1500'}
              // data-aos-delay={'100'}
            >
              <Center w="100%">
                <LazyLoad
                  width={'100%'}
                  once
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Image src={GIF1} w="100%" alt="Ape Default" />
                </LazyLoad>
              </Center>
              <Text color={'#878d95'} fontSize={'18px'} textAlign={'start'}>
                {ReactHtmlParser(
                  `<strong class="strong">${why_mtbc.title}</strong><br></br>`,
                )}
                {ReactHtmlParser(
                  `<ol><li class="mb-3">${why_mtbc.first}</li><li class="mb-3">${why_mtbc.second}</li><li class="mb-3">${why_mtbc.third}</li></ol>`,
                )}
              </Text>
            </SimpleGrid>
          </Fade>
          {() => {
            if (window.screen.width <= 468) {
              return <></>;
            } else {
              return <MSpacer size="2xs" />;
            }
          }}

          <Fade bottom>
            <SimpleGrid
              columns={[1, 1, 2]}
              spacing={'40px'}
              alignItems="center"
              // data-aos={'fade-up'}
              // data-aos-duration={'1500'}
              // data-aos-delay={'100'}
              marginTop={'0px'}
            >
              <Text color={'#878d95'} fontSize={'18px'} textAlign={'start'}>
                {ReactHtmlParser(
                  `<ol start="4"><li class="mb-3">${why_mtbc.fourth}</li><li class="mb-3">${why_mtbc.fifth}</li><li class="mb-3">${why_mtbc.sixth}</li><li class="mb-3">${why_mtbc.seventh}</li></ol>`,
                )}
              </Text>
              <Center w="100%">
                <LazyLoad
                  width={'100%'}
                  once
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Image src={GIF2} w="100%" alt="Ape Default" />
                </LazyLoad>
              </Center>
            </SimpleGrid>
          </Fade>
        </VStack>
      </Container>
    </Center>
  );
};

export default Welcome;
