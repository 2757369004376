import React from 'react';
import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const MSpacer = ({ size, ...rest }) => {
  if (size === '5xs') {
    return <Box w="100%" h="5px" {...rest}></Box>;
  } else if (size === '4xs') {
    return <Box w="100%" h="10px" {...rest}></Box>;
  } else if (size === '3xs') {
    return <Box w="100%" h="20px" {...rest}></Box>;
  } else if (size === '2xs') {
    return <Box w="100%" h="30px" {...rest}></Box>;
  } else if (size === 'xs') {
    return <Box w="100%" h="40px" {...rest}></Box>;
  } else if (size === 'sm') {
    return <Box w="100%" h="50px" {...rest}></Box>;
  } else if (size === 'md') {
    return <Box w="100%" h="60px" {...rest}></Box>;
  } else {
    return <Box w="100%" h="125px" {...rest}></Box>;
  }
};

MSpacer.propTypes = {
  size: PropTypes.string,
};

export default MSpacer;
