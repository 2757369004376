module.exports.why_mtbc = {
  title: 'Why Meta Bunnies Club??',
  first:
    'We have one of the most generous giveaway after completion of 1st and 2nd presale. You will stand a chance to win a grand prize of 20ETH, 3 x 10ETH or 25 of our most exclusive Meta Bunnies NFTs.',
  second:
    'Our Meta Bunnies artworks are fully handcrafted by 11 experienced graphic designers, to ensure that every single piece of NFTs are unique and work of art.',
  third:
    'We will choose one lucky winner of 2ETH, after we complete the first presale. One lucky Meta Bunnies NFT holder will also receive 2ETH after we complete the 2nd presale.',
  fourth:
    'We will take care and control our NFT Floor price to ensure its highly competitive.',
  fifth:
    'Earn you NFT Royalties. We will be giving 20% of the royalties obtained to be distributed equally between our valued NFT holders. The more Meta Bunnies you hold the more you will get.',
  sixth:
    'We have a very strong management team and active community in Discord, Twitter and Instagram',
  seventh:
    'Our Meta Bunnies NFT are based on Ethereum blockchain. Future Roadmap includes adaptation to metaverse and land purchase in Sandbox.',
};
