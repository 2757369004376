import {
  SimpleGrid,
  Center,
  Container,
  VStack,
  HStack,
  Circle,
  Text,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { TiTick } from 'react-icons/ti';
import Messaging from 'react-cssfx-loading/lib/Messaging';

import MHeading from '../atoms/MHeading';
import MSpacer from '../atoms/MSpacer';

import Fade from 'react-reveal/Fade';

import ReactHtmlParser from 'react-html-parser';

import { roadmapData } from '../../data/roadmap';

const Roadmap = () => {
  useEffect(() => {
    // AOS.init({
    //   once: true,
    // });
  }, []);

  const roadmapItems = roadmapData.roadmapItems;

  return (
    <Center w="100%">
      <Container maxW="1200px" mx="auto" px="50px">
        <VStack w="100%" alignItems={'center'}>
          <Fade bottom>
            <MHeading
              type="heading"
              // data-aos={'fade-up'}
              // data-aos-duration={'1500'}
              // data-aos-delay={'100'}
            >
              Roadmap
            </MHeading>
          </Fade>
          <MSpacer size="sm" />
          <Fade bottom>
            <Text
              // data-aos={'fade-up'}
              // data-aos-duration={'1500'}
              // data-aos-delay={'100'}
              color={'#878d95'}
              fontSize={'18px'}
            >
              {ReactHtmlParser(roadmapData.description)}
            </Text>
          </Fade>
          <MSpacer size="sm" />
          <Fade bottom>
            <SimpleGrid
              columns={[1, 1, 1, 2]}
              spacing={20}
              w="100%"
              // data-aos={'fade-up'}
              // data-aos-duration={'1500'}
              // data-aos-delay={'100'}
            >
              {roadmapItems.map((item, index) => (
                <HStack idx={index} alignItems={'flex-start'}>
                  <Circle
                    w="50px"
                    h="50px"
                    backgroundColor={item.completed ? '#78867F' : '#333333'}
                    mr="25px"
                    padding={'5px 5px'}
                  >
                    {item.completed ? (
                      <TiTick
                        style={{
                          height: '30px',
                          width: '30px',
                        }}
                      />
                    ) : (
                      <Messaging color="#C7C7C7" width="5px" height="5px" />
                    )}
                  </Circle>
                  <VStack alignItems={'flex-start'}>
                    <MHeading
                      type="heading"
                      fontSize="18px"
                      mb="8px !important"
                      textAlign="start"
                    >
                      {item.title}
                    </MHeading>
                    <Text
                      textAlign={'start'}
                      color={'#878d95'}
                      fontSize={'18px'}
                    >
                      {item.description}
                    </Text>
                  </VStack>
                </HStack>
              ))}
            </SimpleGrid>
          </Fade>
        </VStack>
      </Container>
    </Center>
  );
};

export default Roadmap;
