/* eslint-disable */

import { MinusIcon, AddIcon } from '@chakra-ui/icons';
import { Container, SimpleGrid, VStack, Button } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { ethers } from 'ethers';
import meta_abi from '../contract/meta_abi.json';

import MButtonWithText from '../components/atoms/MButtonWithText';
import Loader from '../components/molecules/Loader';
import Header from '../components/organisms/Header';
import MHeading from '../components/atoms/MHeading';
import MSpacer from '../components/atoms/MSpacer';
import Carousel from '../components/organisms/Carousel';
import Modal from '../components/molecules/Modal';
import useModal from '../hooks/useModal';
import { MetaMaskLogo } from '../assets';
import MButton from '../components/atoms/MButton';

const Minting = () => {
  const contractAddress = '0x75ffFD4D76266dd52552ed083c40Aac15F6c3946';
  const metaABI = meta_abi.abi;
  const splashDivRef = useRef();

  const [currentAccount, setCurrentAccount] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [minting, setMinting] = useState(false);
  const [oneMintCost, setOneMintCost] = useState(0);
  const [mintingOption, setMintingOption] = useState({
    total_cost_in_eth: 0.0,
    token_num: 1,
  });
  const [saleMinting, setSaleMinting] = useState('');

  const { isOpen, handleModalOpen, handleModalClose } = useModal({
    initialState: false,
  });

  const {
    isOpen: MMIsOpen,
    handleModalOpen: MMHandleModalOpen,
    handleModalClose: MMHandleModalClose,
  } = useModal({
    initialState: false,
  });

  const checkMintTime = async () => {
    let timeNow = Math.floor(Date.now() / 1000);
    let first_presale_mint_start =
      process.env.REACT_APP_FIRST_PRESALE_MINT_START;
    let first_presale_mint_end = process.env.REACT_APP_FIRST_PRESALE_MINT_END;
    let second_presale_mint_start =
      process.env.REACT_APP_SECOND_PRESALE_MINT_START;
    let second_presale_mint_end = process.env.REACT_APP_SECOND_PRESALE_MINT_END;
    let public_mint_start = process.env.REACT_APP_PUBLIC_MINT_START;
    let public_mint_end = process.env.REACT_APP_PUBLIC_MINT_END;

    console.log('timeNow', timeNow);
    console.log('first_presale_mint_start', first_presale_mint_start);
    console.log('first_presale_mint_end', first_presale_mint_end);
    console.log('second_presale_mint_start', second_presale_mint_start);
    console.log('second_presale_mint_end', second_presale_mint_end);
    console.log('public_mint_start', public_mint_start);
    console.log('public_mint_end', public_mint_end);

    if (
      timeNow > first_presale_mint_start &&
      timeNow < first_presale_mint_end
    ) {
      console.log('first presale minting');
      setSaleMinting('First Presale Minting');
    } else if (
      timeNow > second_presale_mint_start &&
      timeNow < second_presale_mint_end
    ) {
      console.log('second presale minting');
      setSaleMinting('Second Presale Minting');
    } else if (timeNow > public_mint_start && timeNow < public_mint_end) {
      console.log('public minting');
      setSaleMinting('Public Minting');
    } else {
      console.log('not minting');
      setSaleMinting(null);
    }
  };

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      MMHandleModalOpen();
      return;
    } else {
      try {
        const accounts = await ethereum.request({
          method: 'eth_requestAccounts',
        });
        if (accounts.length < 1) {
          // please login to metamask
          MMHandleModalOpen();
          return;
        }
        console.log('Found an account! Address: ', accounts[0]);
        setCurrentAccount(accounts[0]);
      } catch (error) {
        MMHandleModalOpen();
      }
    }
  };

  const connectWalletHandler = async () => {
    const { ethereum } = window;

    try {
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      if (accounts.length < 1) {
        MMHandleModalOpen();
        return;
      }
      console.log('Found an account! Address: ', accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (error) {
      MMHandleModalOpen();
    }
  };

  const costCheckHandler = async (token_num) => {
    setMinting(true);
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      let contract = new web3.eth.Contract(metaABI, contractAddress);

      async function checkCost() {
        const numberMint = parseInt(1);
        const oneEth = 1000000000000000000;
        let mintCost = 0;
        if (saleMinting === 'First Presale Minting') {
          mintCost = await contract.methods.firstPresaleMintCost().call();
        } else if (saleMinting === 'Second Presale Minting') {
          mintCost = await contract.methods.secondPresaleMintCost().call();
        } else if (saleMinting === 'Public Minting') {
          mintCost = await contract.methods.publicSaleMintCost().call();
        } else {
          console.log('No minting time');
        }
        mintCost = parseInt(mintCost) / oneEth;
        setOneMintCost(mintCost);
        handleModalOpen();
        let totalCost = mintCost * numberMint;
        setMintingOption({
          total_cost_in_eth: totalCost,
          token_num: numberMint,
        });
      }

      try {
        await checkCost();
        setMinting(false);
      } catch (error) {
        setMinting(false);
      }
    } else {
      MMHandleModalOpen();
    }
  };

  const mintNFTHandler = async () => {
    setMinting(true);

    if (window.ethereum) {
      await window.ethereum.send('eth_requestAccounts');
      window.web3 = new Web3(window.ethereum);

      let accounts = await web3.eth.getAccounts();
      let account = accounts[0];
      let contract = new web3.eth.Contract(metaABI, contractAddress);

      async function mint() {
        const numberMint = parseInt(mintingOption.token_num);
        const oneEth = 1000000000000000000;
        let mintCost = 0;
        if (saleMinting === 'First Presale Minting') {
          mintCost = await contract.methods.firstPresaleMintCost().call();
        } else if (saleMinting === 'Second Presale Minting') {
          mintCost = await contract.methods.secondPresaleMintCost().call();
        } else if (saleMinting === 'Public Minting') {
          mintCost = await contract.methods.publicSaleMintCost().call();
        } else {
          console.log('No minting time');
        }
        mintCost = parseInt(mintCost) / oneEth;
        let totalCost = mintCost * numberMint;
        let weiCost = totalCost * oneEth;
        if (saleMinting === 'First Presale Minting') {
          console.log('Minting first presale initiated');
          await contract.methods
            .firstPresaleMint(numberMint)
            .send({ from: account, value: weiCost.toString() });
        } else if (saleMinting === 'Second Presale Minting') {
          console.log('Minting second presale initiated');
          await contract.methods
            .secondPresaleMint(numberMint)
            .send({ from: account, value: weiCost.toString() });
        } else if (saleMinting === 'Public Minting') {
          console.log('Minting public sale initiated');
          await contract.methods
            .publicSaleMint(numberMint)
            .send({ from: account, value: weiCost.toString() });
        } else {
          console.log('No minting time');
        }
      }

      try {
        await mint();
        setMinting(false);
        handleModalClose();
      } catch (error) {
        handleModalClose();
        setMinting(false);
      }
    } else {
      MMHandleModalOpen();
    }
  };

  useEffect(() => {
    // Set Margin Top to offset header
    const headerHeight = document.querySelector('#header').offsetHeight;
    setHeaderHeight(headerHeight);
    const marginTop = headerHeight + 'px';
    splashDivRef.current.style.marginTop = marginTop;

    // Check if wallet is connected
    checkWalletIsConnected();

    // Check Minting Time
    checkMintTime();
  }, []);

  return (
    <>
      <Loader />

      <VStack bgColor={'black'}>
        <Header />
        <Modal
          id="MMInstallModal"
          modalIsOpen={MMIsOpen}
          onClose={MMHandleModalClose}
          closeBtnPos="none"
        >
          <VStack
            w="100%"
            alignItems={'center'}
            justifyContent="center"
            spacing={10}
          >
            <MetaMaskLogo
              style={{
                maxWidth: '100%',
              }}
            />
            <MHeading color={'black'} type="normal">
              Please install MetaMask to mint
            </MHeading>
            <MButtonWithText>Install MetaMask</MButtonWithText>
          </VStack>
        </Modal>
        <Modal modalIsOpen={isOpen} onClose={handleModalClose}>
          <VStack w="100%">
            <MHeading type="heading" color="black" textAlign="start">
              {saleMinting}
            </MHeading>
            <MSpacer size="3xs" />
            <SimpleGrid
              columns={[1, 1, 2]}
              alignItems={'center'}
              justifyContent={'space-between'}
              w="100%"
            >
              <MHeading type="normal" color="black" textAlign="start">
                Total Token Minting:
              </MHeading>
              <MHeading
                type="head"
                color="black"
                marginBottom={'-5p'}
                position="relative"
              >
                <Button
                  position="absolute"
                  right="15px"
                  onClick={() => {
                    if (
                      mintingOption.token_num === 10 ||
                      mintingOption.token_num > 10
                    ) {
                      return;
                    } else {
                      setMintingOption({
                        total_cost_in_eth:
                          oneMintCost * (mintingOption.token_num + 1),
                        token_num: mintingOption.token_num + 1,
                      });
                    }
                  }}
                >
                  <AddIcon />
                </Button>
                <Button
                  position="absolute"
                  left="15px"
                  onClick={() => {
                    if (
                      mintingOption.token_num === 1 ||
                      mintingOption.token_num < 1
                    ) {
                      return;
                    } else {
                      setMintingOption({
                        total_cost_in_eth:
                          oneMintCost * (mintingOption.token_num - 1),
                        token_num: mintingOption.token_num - 1,
                      });
                    }
                  }}
                >
                  <MinusIcon />
                </Button>
                {mintingOption.token_num}
              </MHeading>
            </SimpleGrid>
            <SimpleGrid
              columns={[1, 1, 2]}
              alignItems={'center'}
              justifyContent={'flex-start'}
              w="100%"
            >
              <MHeading type="normal" color="black" textAlign="start">
                Total Mint Cost:
              </MHeading>
              <MHeading type="head" color="black" marginBottom={'-5p'}>
                {mintingOption.total_cost_in_eth} ETH
              </MHeading>
            </SimpleGrid>

            <MSpacer size="3xs" />

            <MButtonWithText
              onClick={() => {
                mintNFTHandler();
              }}
              loading={minting}
              loadingText={'Minting...'}
            >
              Confirm Mint
            </MButtonWithText>
          </VStack>
        </Modal>
        <VStack
          alignItems={'center'}
          justifyContent={'flex-start'}
          ref={splashDivRef}
          w="100%"
        >
          <Container
            maxW="1200px"
            mx="auto"
            h="100%"
            pt={headerHeight}
            px="50px"
            color={'white'}
            minH={() => {
              const windowHeight = window.innerHeight;

              return windowHeight - headerHeight;
            }}
            d="flex"
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <VStack
              w="100%"
              h="100%"
              justifyContent={'center'}
              alignItems={'center'}
              spacing={10}
            >
              <MHeading type="heading">Bunnies sittin' and waitin'</MHeading>
              <MHeading>
                In the Metaverse, the Meta Bunnies live in peace on a Meta
                Bunnies Land.
              </MHeading>
              {currentAccount ? (
                <MButtonWithText
                  type="heading"
                  theme="metabillionaire"
                  transformOrigin={'center'}
                  display={'flex'}
                  alignItems={'center'}
                  paddingX={'50px'}
                  w={['100%', '100%', '100%', 'fit-content !important']}
                  isLoading={minting}
                  disabled={saleMinting !== null ? false : true}
                  onClick={() => {
                    costCheckHandler();
                  }}
                >
                  {saleMinting !== null ? saleMinting : 'Mint Ended'}
                </MButtonWithText>
              ) : (
                <MButtonWithText
                  type="heading"
                  theme="metabillionaire"
                  transformOrigin={'center'}
                  display={'flex'}
                  alignItems={'center'}
                  paddingX={'50px'}
                  w="fit-content !important"
                  onClick={() => {
                    connectWalletHandler();
                  }}
                >
                  Connect Wallet
                </MButtonWithText>
              )}

              <MSpacer size="sm" />

              <Carousel fullWidth={true} />
            </VStack>
          </Container>
        </VStack>
      </VStack>
    </>
  );
};

export default Minting;
