import React from 'react';

import { IconButton } from '@chakra-ui/react';
import { Center, Container, HStack } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import AOSWrap from '../../components/molecules/AOSWrap';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';

import useWindowResize from '../../hooks/useWindowResize.js';

import {
  Sample17,
  Sample18,
  Sample19,
  Sample20,
  Sample21,
  Sample22,
  Sample23,
  Sample24,
  Sample25,
  Sample26,
  Sample27,
  Sample28,
  Sample29,
  Sample30,
  Sample31,
  Sample32,
} from '../../assets';

// install Swiper modules
SwiperCore.use([Pagination]);
SwiperCore.use([Navigation]);
SwiperCore.use([Autoplay]);

const images = [
  {
    id: 18,
    src: Sample19,
  },
  {
    id: 16,
    src: Sample17,
  },
  {
    id: 17,
    src: Sample18,
  },
  {
    id: 19,
    src: Sample20,
  },
  {
    id: 20,
    src: Sample21,
  },
  {
    id: 21,
    src: Sample22,
  },
  {
    id: 22,
    src: Sample23,
  },
  {
    id: 23,
    src: Sample24,
  },
  {
    id: 24,
    src: Sample25,
  },
  {
    id: 25,
    src: Sample26,
  },
  {
    id: 26,
    src: Sample27,
  },
  {
    id: 27,
    src: Sample28,
  },
  {
    id: 28,
    src: Sample29,
  },
  {
    id: 29,
    src: Sample30,
  },
  {
    id: 30,
    src: Sample31,
  },
  {
    id: 31,
    src: Sample32,
  },
];

const Carousel = ({ fullWidth = false }) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const [width, height] = useWindowResize();

  return (
    <AOSWrap>
      <Center position="relative" w="100vw">
        <Container maxW={fullWidth ? '100vw' : 'container.xl'}>
          <HStack
            spacing="10"
            justifyContent="center"
            w="100%"
            maxW="100%"
            h="auto"
          >
            <Swiper
              slidesPerView={
                fullWidth ? (width > 600 ? 10 : 2) : width > 600 ? 3.5 : 1
              }
              centeredSlides={width > 600 ? false : true}
              spaceBetween={fullWidth ? 20 : 30}
              scrollbar={{ draggable: true }}
              loop={fullWidth ? true : false}
              // centeredSlides={true}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                // @ts-ignore
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                // @ts-ignore
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              pagination={
                fullWidth
                  ? false
                  : {
                      clickable: true,
                    }
              }
              autoplay={{
                delay: 3000,
              }}
              className="mySwiper"
              style={{
                width: '100%',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
              }}
            >
              {images.map((image, idx) => (
                <SwiperSlide key={idx}>
                  <a target="_blank" rel="noreferrer" href="/#">
                    <Image
                      src={image.src}
                      w="auto"
                      h={
                        fullWidth
                          ? ['150px', '150px', '150px', 'auto']
                          : ['auto', 'auto', '300px']
                      }
                      rounded="xl"
                      borderRadius={'30px'}
                      border={'2px solid white'}
                    />
                  </a>
                </SwiperSlide>
              ))}

              <IconButton
                ref={navigationPrevRef}
                aria-label="Prev Slide"
                icon={
                  <ChevronLeftIcon
                    style={{
                      color: '#FFFFFF',
                    }}
                  />
                }
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  left: '10px',
                  zIndex: 61,
                  backgroundColor: 'transparent',
                  border: '1px solid #FFFFFF',
                  borderRadius: '50%',
                  boxShadow: '0px 0px 10px #383838',
                }}
              />
              <IconButton
                ref={navigationNextRef}
                aria-label="Next Slide"
                icon={
                  <ChevronRightIcon
                    style={{
                      color: '#FFFFFF',
                    }}
                  />
                }
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  right: '10px',
                  zIndex: 1001,
                  backgroundColor: 'transparent',
                  border: '1px solid #FFFFFF',
                  borderRadius: '50%',
                  boxShadow: '0px 0px 10px #3838380F',
                }}
              />
            </Swiper>
          </HStack>
        </Container>
      </Center>
    </AOSWrap>
  );
};

export default Carousel;
