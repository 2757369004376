module.exports.faqItems = [
  {
    id: 1,
    question: 'What is Meta Bunnies?',
    answer:
      'Meta Bunnies is the first NFT collection fully hand crafted, with 12 unique attributes, each one different from the others.',
  },
  {
    id: 2,
    question: 'How do i connect with a wallet?',
    answer:
      'To use this site, please install the Metamask browser extension or the Coinbase wallet. Those extensions allow this wesite to interact with your wallet and allow you to manage or mint Meta Bunnies',
  },
  {
    id: 3,
    question: 'How to mint and where to see my NFT after mint?',
    answer:
      'Minting will be available on our website on the day of launch. 1st presale will be on 1st April 2022. Once minted or bought, simply connect to your OpenSea account to see your NFTs(after reveal).',
  },
  {
    id: 4,
    question: 'How much does Meta Bunnies costs?',
    answer:
      '0.06 ETH first presale <br /><br /> 0.07 ETH second presale <br /><br /> 0.08 ETH public sale',
  },
  {
    id: 5,
    question: 'Where can I find more information on Meta Bunnies Club?',
    answer:
      'Join our Discord and open a support ticket, our team is available 24/7.',
  },
];
