import { HStack, Img, Box } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

import MSpacer from '../../components/atoms/MSpacer';

import {
  Sample1,
  Sample2,
  Sample3,
  Sample4,
  Sample5,
  Sample6,
  Sample7,
  Sample8,
  Sample9,
  Sample10,
  Sample11,
  Sample12,
  Sample13,
  Sample14,
  Sample15,
  Sample16,
} from '../../assets';

const images1 = [
  {
    id: 0,
    src: Sample1,
  },
  {
    id: 1,
    src: Sample6,
  },
  {
    id: 2,
    src: Sample7,
  },
  {
    id: 3,
    src: Sample4,
  },
  {
    id: 4,
    src: Sample6,
  },
  {
    id: 1,
    src: Sample1,
  },
  // Start Main
  {
    id: 5,
    src: Sample2,
  },
  {
    id: 6,
    src: Sample3,
  },
  {
    id: 7,
    src: Sample5,
  },
  {
    id: 0,
    src: Sample8,
  },
  // End Main
  {
    id: 2,
    src: Sample6,
  },
  {
    id: 3,
    src: Sample11,
  },
  {
    id: 4,
    src: Sample7,
  },
  {
    id: 5,
    src: Sample4,
  },
  {
    id: 6,
    src: Sample6,
  },
  {
    id: 7,
    src: Sample1,
  },
];

const images2 = [
  {
    id: 4,
    src: Sample9,
  },
  {
    id: 5,
    src: Sample10,
  },
  {
    id: 6,
    src: Sample11,
  },
  {
    id: 7,
    src: Sample16,
  },
  {
    id: 4,
    src: Sample15,
  },
  {
    id: 5,
    src: Sample11,
  },
  // Start Main
  {
    id: 7,
    src: Sample12,
  },
  {
    id: 4,
    src: Sample13,
  },
  {
    id: 5,
    src: Sample14,
  },
  {
    id: 6,
    src: Sample16,
  },
  // End Main
  {
    id: 6,
    src: Sample9,
  },
  {
    id: 7,
    src: Sample10,
  },
  {
    id: 4,
    src: Sample11,
  },
];

const ImageScroll = () => {
  const carousel1Ref = useRef();
  const carousel2Ref = useRef();

  const handleScroll = (e) => {
    const window = e.currentTarget;
    const currentY = window.scrollY;
    if (currentY > 800) {
      const offSet1 = currentY - 800;
      const offSet2 = currentY - 800 + 800;
      carousel1Ref.current.style.transform = `translateX(${offSet1}px)`;
      carousel2Ref.current.style.transform = `translateX(-${offSet2}px)`;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', (e) => handleScroll(e));
    return () => {
      window.removeEventListener('scroll', (e) => handleScroll(e));
    };
  }, []);

  return (
    <Box
      maxW={['400px', '800px', '100vw']}
      pading="24px"
      px={'50px'}
      transform={'translateY(-25%) rotate(-7deg);'}
      marginBottom={'0px'}
      w="100%"
      marginTop={'80px !important'}
    >
      <Box margin={'0px 0px !important'}>
        <Box
          id="gallery-translate"
          transform={[
            'translateX(-450%)',
            'translateX(-160%)',
            'translateX(-160%)',
          ]}
        >
          <HStack
            id="gallery-container"
            position={'relative'}
            ref={carousel1Ref}
            spacing={[4, 8, 10]}
          >
            {images1.map((image) => (
              <Img
                key={image.id}
                src={image.src}
                alt="random"
                w={['140px', '200px', '250px']}
                h={['140px', '200px', '250px']}
                borderRadius={'30px'}
                border={'2px solid white'}
              />
            ))}
          </HStack>
        </Box>
        <MSpacer size="xs" />
        <Box id="gallery-translate">
          <HStack
            id="gallery-container"
            position={'relative'}
            ref={carousel2Ref}
            spacing={[4, 8, 10]}
          >
            {images2.map((image) => (
              <Img
                key={image.id}
                src={image.src}
                alt="random"
                w={['140px', '200px', '250px']}
                h={['140px', '200px', '250px']}
                borderRadius={'30px'}
                border={'2px solid white'}
              />
            ))}
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageScroll;
