import React, { useRef } from 'react';
import { VStack } from '@chakra-ui/react';

import Header from '../components/organisms/Header';
import Splash from '../components/organisms/Splash';
import Welcome from '../components/organisms/Welcome';
import ImageScroll from '../components/organisms/ImageScroll';
import Carousel from '../components/organisms/Carousel';
import Roadmap from '../components/organisms/Roadmap';
import Faq from '../components/organisms/Faq';
import Team from '../components/organisms/Team';

import MSpacer from '../components/atoms/MSpacer';

import Footer from '../components/organisms/Footer';
import Loader from '../components/molecules/Loader';

const Home = ({ onWeb3Login }) => {
  const aboutRef = useRef();

  return (
    <>
      <Loader />
      <VStack bgColor={'#000000'}>
        <Header />
        <main
          style={{
            overflowX: 'hidden',
            overflowY: 'hidden',
            maxWidth: '100%',
            margin: '0 auto',
          }}
        >
          <Splash />
          <MSpacer size="sm" />
          <MSpacer size="sm" />
          <ImageScroll />
        </main>
        <MSpacer size="4xs" id="about" ref={aboutRef} />
        <Welcome />
        <MSpacer size="xl" id="roadmap" />
        <Roadmap />
        <MSpacer size="xl" id="carousel" />
        <Carousel />
        <MSpacer size="xl" id="faq" />
        <Faq />
        <MSpacer size="xl" id="team" />
        <Team />
        <Footer />
      </VStack>
    </>
  );
};

export default Home;
